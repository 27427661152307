<template>
  <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="15.8" y="1.5" width="2" height="1" fill="black" />
    <rect x="17.8" y="2.5" width="1" height="1" fill="black" />
    <rect x="16.8" y="2.5" width="1" height="1" fill="black" />
    <rect x="2.79999" y="17.5" width="1" height="1" fill="black" />
    <rect x="3.79999" y="17.5" width="1" height="1" fill="black" />
    <rect x="18.8" y="3.5" width="1" height="1" fill="black" />
    <rect x="17.8" y="3.5" width="1" height="1" fill="black" />
    <rect x="3.79999" y="18.5" width="1" height="1" fill="black" />
    <rect x="4.79999" y="18.5" width="1" height="1" fill="black" />
    <rect x="19.8" y="4.5" width="1" height="1" fill="black" />
    <rect x="18.8" y="4.5" width="1" height="1" fill="black" />
    <rect x="4.79999" y="19.5" width="1" height="1" fill="black" />
    <rect x="5.79999" y="19.5" width="1" height="1" fill="black" />
    <rect x="20.8" y="5.5" width="1" height="1" fill="black" />
    <rect x="19.8" y="5.5" width="1" height="1" fill="black" />
    <rect x="5.79999" y="20.5" width="1" height="1" fill="black" />
    <rect x="6.79999" y="20.5" width="1" height="1" fill="black" />
    <rect x="21.8" y="6.5" width="1" height="1" fill="black" />
    <rect x="20.8" y="6.5" width="1" height="1" fill="black" />
    <rect x="6.79999" y="21.5" width="1" height="1" fill="black" />
    <rect x="7.79999" y="21.5" width="1" height="1" fill="black" />
    <rect x="22.8" y="7.5" width="1" height="1" fill="black" />
    <rect x="21.8" y="7.5" width="1" height="1" fill="black" />
    <rect x="7.79999" y="22.5" width="1" height="1" fill="black" />
    <rect x="8.79999" y="22.5" width="1" height="1" fill="black" />
    <rect x="23.8" y="8.5" width="1" height="1" fill="black" />
    <rect x="22.8" y="8.5" width="1" height="1" fill="black" />
    <rect x="8.79999" y="23.5" width="1" height="1" fill="black" />
    <rect x="9.79999" y="23.5" width="1" height="1" fill="black" />
    <rect x="24.8" y="9.5" width="1" height="1" fill="black" />
    <rect x="23.8" y="9.5" width="1" height="1" fill="black" />
    <rect x="9.79999" y="24.5" width="1" height="1" fill="black" />
    <rect x="10.8" y="24.5" width="1" height="1" fill="black" />
    <rect x="25.8" y="10.5" width="1" height="1" fill="black" />
    <rect x="24.8" y="10.5" width="1" height="1" fill="black" />
    <rect x="10.8" y="25.5" width="1" height="1" fill="black" />
    <rect x="11.8" y="25.5" width="1" height="1" fill="black" />
    <rect x="26.8" y="11.5" width="1" height="1" fill="black" />
    <rect x="25.8" y="11.5" width="1" height="1" fill="black" />
    <rect x="11.8" y="26.5" width="1" height="1" fill="black" />
    <rect x="12.8" y="26.5" width="1" height="1" fill="black" />
    <rect x="27.8" y="12.5" width="1" height="1" fill="black" />
    <rect x="26.8" y="12.5" width="1" height="1" fill="black" />
    <rect x="12.8" y="27.5" width="1" height="1" fill="black" />
    <rect x="13.8" y="27.5" width="1" height="1" fill="black" />
    <rect x="28.8" y="13.5" width="1" height="1" fill="black" />
    <rect x="27.8" y="13.5" width="1" height="1" fill="black" />
    <rect x="13.8" y="28.5" width="1" height="1" fill="black" />
    <rect x="14.8" y="28.5" width="1" height="1" fill="black" />
    <rect x="29.8" y="14.5" width="1" height="1" fill="black" />
    <rect x="28.8" y="14.5" width="1" height="1" fill="black" />
    <rect x="14.8" y="29.5" width="1" height="1" fill="black" />
    <rect x="15.8" y="29.5" width="1" height="1" fill="black" />
    <rect x="15.8" y="2.5" width="1" height="1" transform="rotate(90 15.8 2.5)" fill="black" />
    <rect x="16.8" y="2.5" width="1" height="1" transform="rotate(90 16.8 2.5)" fill="black" />
    <rect x="30.8" y="17.5" width="1" height="1" transform="rotate(90 30.8 17.5)" fill="black" />
    <rect x="29.8" y="17.5" width="1" height="1" transform="rotate(90 29.8 17.5)" fill="black" />
    <rect x="14.8" y="3.5" width="1" height="1" transform="rotate(90 14.8 3.5)" fill="black" />
    <rect x="15.8" y="3.5" width="1" height="1" transform="rotate(90 15.8 3.5)" fill="black" />
    <rect x="29.8" y="18.5" width="1" height="1" transform="rotate(90 29.8 18.5)" fill="black" />
    <rect x="28.8" y="18.5" width="1" height="1" transform="rotate(90 28.8 18.5)" fill="black" />
    <rect x="13.8" y="4.5" width="1" height="1" transform="rotate(90 13.8 4.5)" fill="black" />
    <rect x="14.8" y="4.5" width="1" height="1" transform="rotate(90 14.8 4.5)" fill="black" />
    <rect x="28.8" y="19.5" width="1" height="1" transform="rotate(90 28.8 19.5)" fill="black" />
    <rect x="27.8" y="19.5" width="1" height="1" transform="rotate(90 27.8 19.5)" fill="black" />
    <rect x="12.8" y="5.5" width="1" height="1" transform="rotate(90 12.8 5.5)" fill="black" />
    <rect x="13.8" y="5.5" width="1" height="1" transform="rotate(90 13.8 5.5)" fill="black" />
    <rect x="27.8" y="20.5" width="1" height="1" transform="rotate(90 27.8 20.5)" fill="black" />
    <rect x="26.8" y="20.5" width="1" height="1" transform="rotate(90 26.8 20.5)" fill="black" />
    <rect x="11.8" y="6.5" width="1" height="1" transform="rotate(90 11.8 6.5)" fill="black" />
    <rect x="12.8" y="6.5" width="1" height="1" transform="rotate(90 12.8 6.5)" fill="black" />
    <rect x="26.8" y="21.5" width="1" height="1" transform="rotate(90 26.8 21.5)" fill="black" />
    <rect x="25.8" y="21.5" width="1" height="1" transform="rotate(90 25.8 21.5)" fill="black" />
    <rect x="10.8" y="7.5" width="1" height="1" transform="rotate(90 10.8 7.5)" fill="black" />
    <rect x="11.8" y="7.5" width="1" height="1" transform="rotate(90 11.8 7.5)" fill="black" />
    <rect x="25.8" y="22.5" width="1" height="1" transform="rotate(90 25.8 22.5)" fill="black" />
    <rect x="24.8" y="22.5" width="1" height="1" transform="rotate(90 24.8 22.5)" fill="black" />
    <rect x="9.79999" y="8.5" width="1" height="1" transform="rotate(90 9.79999 8.5)" fill="black" />
    <rect x="10.8" y="8.5" width="1" height="1" transform="rotate(90 10.8 8.5)" fill="black" />
    <rect x="24.8" y="23.5" width="1" height="1" transform="rotate(90 24.8 23.5)" fill="black" />
    <rect x="23.8" y="23.5" width="1" height="1" transform="rotate(90 23.8 23.5)" fill="black" />
    <rect x="8.79999" y="9.5" width="1" height="1" transform="rotate(90 8.79999 9.5)" fill="black" />
    <rect x="9.79999" y="9.5" width="1" height="1" transform="rotate(90 9.79999 9.5)" fill="black" />
    <rect x="23.8" y="24.5" width="1" height="1" transform="rotate(90 23.8 24.5)" fill="black" />
    <rect x="22.8" y="24.5" width="1" height="1" transform="rotate(90 22.8 24.5)" fill="black" />
    <rect x="7.79999" y="10.5" width="1" height="1" transform="rotate(90 7.79999 10.5)" fill="black" />
    <rect x="8.79999" y="10.5" width="1" height="1" transform="rotate(90 8.79999 10.5)" fill="black" />
    <rect x="22.8" y="25.5" width="1" height="1" transform="rotate(90 22.8 25.5)" fill="black" />
    <rect x="21.8" y="25.5" width="1" height="1" transform="rotate(90 21.8 25.5)" fill="black" />
    <rect x="6.79999" y="11.5" width="1" height="1" transform="rotate(90 6.79999 11.5)" fill="black" />
    <rect x="7.79999" y="11.5" width="1" height="1" transform="rotate(90 7.79999 11.5)" fill="black" />
    <rect x="21.8" y="26.5" width="1" height="1" transform="rotate(90 21.8 26.5)" fill="black" />
    <rect x="20.8" y="26.5" width="1" height="1" transform="rotate(90 20.8 26.5)" fill="black" />
    <rect x="5.79999" y="12.5" width="1" height="1" transform="rotate(90 5.79999 12.5)" fill="black" />
    <rect x="6.79999" y="12.5" width="1" height="1" transform="rotate(90 6.79999 12.5)" fill="black" />
    <rect x="20.8" y="27.5" width="1" height="1" transform="rotate(90 20.8 27.5)" fill="black" />
    <rect x="19.8" y="27.5" width="1" height="1" transform="rotate(90 19.8 27.5)" fill="black" />
    <rect x="4.79999" y="13.5" width="1" height="1" transform="rotate(90 4.79999 13.5)" fill="black" />
    <rect x="5.79999" y="13.5" width="1" height="1" transform="rotate(90 5.79999 13.5)" fill="black" />
    <rect x="19.8" y="28.5" width="1" height="1" transform="rotate(90 19.8 28.5)" fill="black" />
    <rect x="18.8" y="28.5" width="1" height="1" transform="rotate(90 18.8 28.5)" fill="black" />
    <rect x="3.79999" y="14.5" width="1" height="1" transform="rotate(90 3.79999 14.5)" fill="black" />
    <rect x="4.79999" y="14.5" width="1" height="1" transform="rotate(90 4.79999 14.5)" fill="black" />
    <rect x="18.8" y="29.5" width="1" height="1" transform="rotate(90 18.8 29.5)" fill="black" />
    <rect x="17.8" y="29.5" width="1" height="1" transform="rotate(90 17.8 29.5)" fill="black" />
    <rect x="15.8" y="30.5" width="2" height="1" fill="black" />
    <rect x="2.79999" y="15.5" width="2" height="1" transform="rotate(90 2.79999 15.5)" fill="black" />
    <rect x="3.79999" y="15.5" width="2" height="1" transform="rotate(90 3.79999 15.5)" fill="black" />
    <rect x="4.79999" y="17.5" width="1" height="1" fill="black" />
    <rect x="5.79999" y="18.5" width="1" height="1" fill="black" />
    <rect x="6.79999" y="19.5" width="1" height="1" fill="black" />
    <rect x="7.79999" y="20.5" width="1" height="1" fill="black" />
    <rect x="8.79999" y="21.5" width="1" height="1" fill="black" />
    <rect x="9.79999" y="22.5" width="1" height="1" fill="black" />
    <rect x="10.8" y="23.5" width="1" height="1" fill="black" />
    <rect x="11.8" y="24.5" width="1" height="1" fill="black" />
    <rect x="12.8" y="25.5" width="1" height="1" fill="black" />
    <rect x="13.8" y="26.5" width="1" height="1" fill="black" />
    <rect x="14.8" y="27.5" width="1" height="1" fill="black" />
    <rect x="15.8" y="28.5" width="1" height="1" fill="black" />
    <rect x="16.8" y="3.5" width="1" height="1" transform="rotate(90 16.8 3.5)" fill="black" />
    <rect x="15.8" y="4.5" width="1" height="1" transform="rotate(90 15.8 4.5)" fill="black" />
    <rect x="14.8" y="5.5" width="1" height="1" transform="rotate(90 14.8 5.5)" fill="black" />
    <rect x="13.8" y="6.5" width="1" height="1" transform="rotate(90 13.8 6.5)" fill="black" />
    <rect x="12.8" y="7.5" width="1" height="1" transform="rotate(90 12.8 7.5)" fill="black" />
    <rect x="11.8" y="8.5" width="1" height="1" transform="rotate(90 11.8 8.5)" fill="black" />
    <rect x="10.8" y="9.5" width="1" height="1" transform="rotate(90 10.8 9.5)" fill="black" />
    <rect x="9.79999" y="10.5" width="1" height="1" transform="rotate(90 9.79999 10.5)" fill="black" />
    <rect x="8.79999" y="11.5" width="1" height="1" transform="rotate(90 8.79999 11.5)" fill="black" />
    <rect x="7.79999" y="12.5" width="1" height="1" transform="rotate(90 7.79999 12.5)" fill="black" />
    <rect x="6.79999" y="13.5" width="1" height="1" transform="rotate(90 6.79999 13.5)" fill="black" />
    <rect x="5.79999" y="14.5" width="1" height="1" transform="rotate(90 5.79999 14.5)" fill="black" />
    <rect x="4.79999" y="15.5" width="2" height="1" transform="rotate(90 4.79999 15.5)" fill="black" />
    <rect x="31.8" y="15.5" width="2" height="1" transform="rotate(90 31.8 15.5)" fill="black" />
    <rect x="30.8" y="15.5" width="2" height="1" transform="rotate(90 30.8 15.5)" fill="black" />
    <rect x="16.8" y="3.5" width="1" height="1" fill="black" />
    <rect x="17.8" y="4.5" width="1" height="1" fill="black" />
    <rect x="18.8" y="5.5" width="1" height="1" fill="black" />
    <rect x="19.8" y="6.5" width="1" height="1" fill="black" />
    <rect x="20.8" y="7.5" width="1" height="1" fill="black" />
    <rect x="21.8" y="8.5" width="1" height="1" fill="black" />
    <rect x="22.8" y="9.5" width="1" height="1" fill="black" />
    <rect x="23.8" y="10.5" width="1" height="1" fill="black" />
    <rect x="24.8" y="11.5" width="1" height="1" fill="black" />
    <rect x="25.8" y="12.5" width="1" height="1" fill="black" />
    <rect x="26.8" y="13.5" width="1" height="1" fill="black" />
    <rect x="27.8" y="14.5" width="1" height="1" fill="black" />
    <rect x="28.8" y="17.5" width="1" height="1" transform="rotate(90 28.8 17.5)" fill="black" />
    <rect x="27.8" y="18.5" width="1" height="1" transform="rotate(90 27.8 18.5)" fill="black" />
    <rect x="26.8" y="19.5" width="1" height="1" transform="rotate(90 26.8 19.5)" fill="black" />
    <rect x="25.8" y="20.5" width="1" height="1" transform="rotate(90 25.8 20.5)" fill="black" />
    <rect x="24.8" y="21.5" width="1" height="1" transform="rotate(90 24.8 21.5)" fill="black" />
    <rect x="23.8" y="22.5" width="1" height="1" transform="rotate(90 23.8 22.5)" fill="black" />
    <rect x="22.8" y="23.5" width="1" height="1" transform="rotate(90 22.8 23.5)" fill="black" />
    <rect x="21.8" y="24.5" width="1" height="1" transform="rotate(90 21.8 24.5)" fill="black" />
    <rect x="20.8" y="25.5" width="1" height="1" transform="rotate(90 20.8 25.5)" fill="black" />
    <rect x="19.8" y="26.5" width="1" height="1" transform="rotate(90 19.8 26.5)" fill="black" />
    <rect x="18.8" y="27.5" width="1" height="1" transform="rotate(90 18.8 27.5)" fill="black" />
    <rect x="17.8" y="28.5" width="1" height="1" transform="rotate(90 17.8 28.5)" fill="black" />
    <rect x="29.8" y="15.5" width="2" height="1" transform="rotate(90 29.8 15.5)" fill="black" />
    <rect x="9.79999" y="12.5" width="1" height="1" fill="black" />
    <rect x="13.8" y="12.5" width="1" height="1" fill="black" />
    <rect x="15.8" y="12.5" width="1" height="1" fill="black" />
    <rect x="16.8" y="12.5" width="1" height="1" fill="black" />
    <rect x="17.8" y="12.5" width="1" height="1" fill="black" />
    <rect x="19.8" y="12.5" width="1" height="1" fill="black" />
    <rect x="20.8" y="12.5" width="1" height="1" fill="black" />
    <rect x="21.8" y="12.5" width="1" height="1" fill="black" />
    <rect x="21.8" y="13.5" width="1" height="1" fill="black" />
    <rect x="21.8" y="14.5" width="1" height="1" fill="black" />
    <rect x="21.8" y="17.5" width="1" height="1" fill="black" />
    <rect x="21.8" y="15.5" width="1" height="1" fill="black" />
    <rect x="21.8" y="18.5" width="1" height="1" fill="black" />
    <rect x="21.8" y="16.5" width="1" height="1" fill="black" />
    <rect x="21.8" y="19.5" width="1" height="1" fill="black" />
    <rect x="22.8" y="12.5" width="1" height="1" fill="black" />
    <rect x="23.8" y="12.5" width="1" height="1" fill="black" />
    <rect x="15.8" y="13.5" width="1" height="1" fill="black" />
    <rect x="15.8" y="14.5" width="1" height="1" fill="black" />
    <rect x="15.8" y="17.5" width="1" height="1" fill="black" />
    <rect x="16.8" y="15.5" width="1" height="1" fill="black" />
    <rect x="15.8" y="16.5" width="1" height="1" fill="black" />
    <rect x="15.8" y="19.5" width="1" height="1" fill="black" />
    <rect x="15.8" y="15.5" width="1" height="1" fill="black" />
    <rect x="15.8" y="18.5" width="1" height="1" fill="black" />
    <rect x="9.79999" y="13.5" width="1" height="1" fill="black" />
    <rect x="13.8" y="13.5" width="1" height="1" fill="black" />
    <rect x="10.8" y="13.5" width="1" height="1" fill="black" />
    <rect x="10.8" y="14.5" width="1" height="1" fill="black" />
    <rect x="11.8" y="15.5" width="1" height="1" fill="black" />
    <rect x="11.8" y="16.5" width="1" height="1" fill="black" />
    <rect x="12.8" y="17.5" width="1" height="1" fill="black" />
    <rect x="12.8" y="18.5" width="1" height="1" fill="black" />
    <rect x="9.79999" y="14.5" width="1" height="1" fill="black" />
    <rect x="9.79999" y="17.5" width="1" height="1" fill="black" />
    <rect x="13.8" y="14.5" width="1" height="1" fill="black" />
    <rect x="13.8" y="17.5" width="1" height="1" fill="black" />
    <rect x="9.79999" y="15.5" width="1" height="1" fill="black" />
    <rect x="9.79999" y="18.5" width="1" height="1" fill="black" />
    <rect x="13.8" y="15.5" width="1" height="1" fill="black" />
    <rect x="13.8" y="18.5" width="1" height="1" fill="black" />
    <rect x="9.79999" y="16.5" width="1" height="1" fill="black" />
    <rect x="9.79999" y="19.5" width="1" height="1" fill="black" />
    <rect x="13.8" y="16.5" width="1" height="1" fill="black" />
    <rect x="13.8" y="19.5" width="1" height="1" fill="black" />
  </svg>
</template>
