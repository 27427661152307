import { defineStore } from 'pinia'
import ApiService from '../api/ApiService'

const useUserStore = defineStore('user', {
  state: () => ({
    userInfo: null,
    notifyKeys: [
      'notify_new_level',
      'notify_tg_channel_sub',
      'notify_wallet_connect',
      'notify_3_hour',
      'notify_24_hour',
    ],
    needToShowModals: [],
  }),
  actions: {
    setUser(user: any): void {
      this.userInfo = user
    },
    async setActiveModals(): Promise<void> {
      return new Promise((resolve) => {
        for (const [key, value] of Object.entries(this.userInfo)) {
          if (this.notifyKeys.includes(key) && value.is_view === 1) {
            this.addToNeedToShowModalItem([key, value.is_required])
          }
        }
        resolve()
      })
    },
    unsetActiveModal(key: string) {
      const index = this.needToShowModals.indexOf(key)
      if (index !== -1) {
        this.needToShowModals.splice(index, 1)
      }
    },
    clearActiveModal() {
      this.needToShowModals = []
    },
    addToNeedToShowModalItem(item) {
      this.needToShowModals.push(item)
    },
    async getUserFromApi() {
      this.userInfo = await ApiService.getInstance().getUserData()
      return this.userInfo
    },
  },
})

export { useUserStore }
