<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Loader',
  props: {
    customClass: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
})
</script>

<template>
  <div class="flex items-center justify-center h-full">
    <div
      class="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-32 w-32"
      :class="customClass"
    ></div>
  </div>
</template>

<style scoped>
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader {
  border-top-color: #3490dc; /* Change this color to customize the spinner */
  animation: spin 1s linear infinite;
}
</style>
