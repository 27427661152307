import { createRouter, createWebHashHistory, createWebHistory, RouteRecordRaw } from 'vue-router'

import MainLayout from '../layouts/MainLayout.vue'
import TechnicalWork from '../pages/errors/TechnicalWork.vue'
import NotFound from '../pages/NotFound.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/admind23981z0k1/:pathMatch(.*)*',
    redirect: { name: 'dashboard' },
  },
  {
    name: 'home',
    path: '/home',
    component: MainLayout,
    redirect: { name: 'main' },
    children: [
      {
        name: 'main',
        path: 'dashboard',
        component: () => import('../pages/main/Dashboard.vue'),
        meta: { requiresAuth: true },
      },
      {
        name: 'wallet',
        path: 'wallet',
        component: () => import('../pages/main/Wallet.vue'),
        meta: { requiresAuth: true },
      },
      {
        name: 'task',
        path: 'task',
        component: () => import('../pages/main/Task.vue'),
        meta: { requiresAuth: true },
      },
      {
        name: 'store',
        path: 'store',
        component: () => import('../pages/main/Store.vue'),
        meta: { requiresAuth: true },
      },
      {
        name: 'invite_friends',
        path: 'invite-friends',
        component: () => import('../pages/main/InviteFriends.vue'),
        meta: { requiresAuth: true },
      },
      {
        name: 'nft',
        path: 'nft',
        component: () => import('../pages/main/Nft.vue'),
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    name: 'introduce',
    path: '/introduce',
    component: () => import('../pages/welcome/OnboardPage.vue'),
  },
  {
    name: 'welcome',
    path: '/',
    component: () => import('../pages/welcome/Welcome.vue'),
  },
  {
    name: 'game',
    path: '/game',
    component: () => import('../pages/main/Game.vue'),
  },
  {
    name: '404',
    path: '/404',
    component: NotFound,
  },
  {
    name: 'teh_work',
    path: '/tehwork',
    component: TechnicalWork,
  },
]

const router = createRouter({
  history: createWebHistory(),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    if (to.hash) {
      return { el: to.hash, behavior: 'smooth' }
    } else {
      window.scrollTo(0, 0)
    }
  },
  routes,
})

export default router
