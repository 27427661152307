<script setup>
import VFooter from '../components/base/VFooter.vue'
import { RouterView, useRouter } from 'vue-router'
import CryptoModal from '../components/modals/CryptoModal.vue'
import { useCryptoModalStore } from '../stores/crypto-modal'
import { storeToRefs } from 'pinia'
import { onBeforeMount, onMounted, ref } from 'vue'
import { usePreloaderStore } from '../stores/preloader'
import ApiService from '../api/ApiService'
import { useBalanceStore } from '../stores/balance'
import { useUserStore } from '../stores/user'
import { setI18nLanguage } from '../i18n'
import { queryClient } from '../plugins/vueQuery'

defineOptions({
  name: 'MainLayout',
})

const store = useCryptoModalStore()
const { visible } = storeToRefs(store)
const preloaderStore = usePreloaderStore()
const balanceStore = useBalanceStore()
const userStore = useUserStore()
const router = useRouter()
const isBaseDataLoaded = ref(false)

onBeforeMount(async () => {
  preloaderStore.show()
  try {
    const response = await ApiService.getInstance().updateAuth()

    if (response.status && response.status !== 'success') {
      const name = response.result === 'maintenance' ? 'teh_work' : response.result === 'ban' ? '404' : 'welcome'
      await router.push({ name })
      return
    }
    await queryClient.prefetchQuery({
      queryKey: ['userData'],
      queryFn: async () => {
        const data = await userStore.getUserFromApi()
        if (data?.lang && typeof data.lang === 'string') {
          setI18nLanguage(data.lang)
        }
        balanceStore.setBalance(data?.balance ?? 0)
        return data
      },
    })
  } catch (error) {
    console.error('Auth error:', error)
    await router.push({ name: 'welcome' })
  } finally {
    preloaderStore.hide()
  }
})

onMounted(async () => {
  isBaseDataLoaded.value = true
  await queryClient.prefetchQuery({
    queryKey: ['store'],
    queryFn: async () => {
      const response = await ApiService.getInstance().getStoreCards()
      const statusOrder = {
        available: 1,
        wait: 2,
        unvailable: 3,
        purchased: 4,
        expired: 5,
      }

      return {
        categories: Object.values(response.cats),
        items: [...Object.values(response.new), ...Object.values(response.purchased)].sort((a, b) => {
          const aOrder = statusOrder[a.status] ?? Infinity
          const bOrder = statusOrder[b.status] ?? Infinity
          return aOrder - bOrder
        }),
      }
    },
  })
  await queryClient.prefetchQuery({
    queryKey: ['userFrensData'],
    queryFn: async () => {
      const response = await ApiService.getInstance().getUserFrensData()
      return {
        topUsersByBalance: response.top5_by_balance,
        topUsersByRef: response.top5_by_referrals,
        referralReward: response.user.referrals_claim,
        friendList: response.user.last5_referrals,
        inviteLink: response.user.invite_link,
        referralBonus: parseInt(response.user.reward_for_referral),
        topUsersByBalanceAtWeek: response.top5_by_week_balance,
        topUsersByRefAtWeek: response.top5_by_week_referrals,
      }
    },
  })
})
</script>

<template>
  <div class="min-h-screen h-full bg-black text-white flex flex-col items-center w-full">
    <div class="absolute inset-0 bg-cover bg-center bg-opacity-layer"></div>
    <div class="flex-1 flex flex-col justify-between main-bg relative p-4" :class="[visible ? 'opacity-50' : '']">
      <RouterView v-slot="{ Component }">
        <Component :is="Component" />
      </RouterView>
      <VFooter class="sticky bottom-0" />
    </div>
    <Transition name="slide-up" mode="out-in">
      <CryptoModal />
    </Transition>
  </div>
</template>

<style scoped>
* {
  transition: all 0.5s ease;
}
.main-bg {
  position: relative;
  background: radial-gradient(50% 50% at 50% 50%, #1b1c1b 0%, #0b0c0a 100%);
}
.main-bg::before {
  content: ' ';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.1;
  background-image: url('/image/bg-main.png');
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.slide-enter-active,
.slide-leave-active {
  transition: transform 0.7s ease;
}
.slide-enter-from {
  transform: translateX(100%);
}
.slide-leave-to {
  transform: translateX(-100%);
}

.scale-enter-active,
.scale-leave-active {
  transition: transform 0.7s ease;
}
.scale-enter-from,
.scale-leave-to {
  transform: scale(0.9);
}

.slide-up-enter-active,
.slide-up-leave-active {
  transition: transform 0.5s ease;
}
.slide-up-enter-from,
.slide-up-leave-to {
  transform: translateY(100%);
}
.page-enter-active,
.page-leave-active {
  transition:
    opacity 0.5s,
    transform 0.5s;
}
.page-enter-from,
.page-leave-to {
  opacity: 0;
  transform: translateX(-30px);
}
.page-leave-active {
  position: absolute;
}

:deep(.animate__animated) {
  animation-duration: 0.4s;
}

:deep(.faster) {
  animation-duration: 0.3s;
}

:deep(.animate__animated) {
  will-change: transform, opacity;
  backface-visibility: hidden;
  perspective: 1000px;
}
</style>
