{
  "basic": {
    "hello": "Привіт",
    "start": "Почати",
    "claim": "Отримати",
    "loading": "Будь ласка, зачекайте, йде завантаження...",
    "edit": "Редагувати",
    "done": "Готово!",
    "deposit": "Депозит",
    "custom_wallet": "користувацький гаманець",
    "friends": "Друзі",
    "world": "Світ",
    "top_5_by_ref": "ТОП 5 \nлідери по рефералкам",
    "top_5_by_balance": "ТОП 5 \nлідери по коінам",
    "text_copy": "Посилання скопійовано",
    "teh_work": "ТЕХНІЧНІ РОБОТИ",
    "not_enough_money": "Бракує грошей",
    "success": "Успіх",
    "start_farming": "Запустити фармінг",
    "farming_button": "Накопичується {amount} / cек.",
    "claim_coins": "Забрати {amount}",
    "bonus": "Бонус:",
    "summary": "Разом:",
    "score": "Набрано очок:",
    "back_to_home": "Повернутися на головну",
    "game_over": "Гру завершено",
    "win_game": "Вітаємо, ви виграли!",
    "loose_game": "Ви програли, спробуйте ще раз",
    "time": "Час",
    "score_user": "Рахунок",
    "change_lang": "Змінити мову на {lang}"
  },
  "header": {
    "close": "Закрити",
    "profit_for_one_tap": "Прибуток за 1 тап",
    "level": "рівень",
    "profit_per_hour": "Прибуток за годину",
    "level_number": "Рів.{level}"
  },
  "footer": {
    "main": "Головна",
    "store": "Магазин",
    "task": "Завдання",
    "wallet": "Гаманець",
    "invite_friends": "Друзі"
  },
  "modals": {
    "crypto_modal": {
      "wallet_saved": "Гаманець збережено",
      "wallet_save_error": "Помилка! Будь ласка, перевірте введений вами гаманець.",
      "address_text": "Введіть адресу вашого гаманця USDT ERC-20 для отримання токенів",
      "have_account": "У вас є обліковий запис {alias} ?",
      "receive_rewards": "Щоб отримати нагороди, вам потрібно додати адресу вашого гаманця",
      "wallet_filled_title": "Гаманець вже заповнено",
      "wallet_filled_desc": "Гаманець вже заповнено, хочете його змінити?",
      "inputs": {
        "postal_address": {
          "placeholder": "гаманець"
        }
      },
      "buttons": {
        "create_account": "Створити обліковий запис",
        "there_are": "Так, є"
      }
    },
    "info_modal": {
      "titles": {
        "notify_3_hour": "Вітаємо",
        "notify_24_hour": "Вітаємо",
        "notify_wallet_connect": "Підключіть свій гаманець",
        "notify_new_level": "Рівень підвищено",
        "notify_tg_channel_sub": "Для продовження Вам необхідно підписатися на наш Telegram-канал"
      },
      "descriptions": {
        "notify_3_hour": "Ви провели 3 години в нашому додатку! Дякуємо за вашу активність.\n\nЯк нагороду ми пропонуємо вам спеціальний бонус. Хотіли б ви отримати його зараз?\n\nНатисніть \"Отримати нагороду\", щоб забрати бонус, або \"Скасувати\", якщо хочете зробити це пізніше.",
        "notify_24_hour": "Ви провели 24 години в нашому додатку! Дякуємо за вашу активність.\n\nЯк нагороду ми пропонуємо вам спеціальний бонус. Хотіли б ви отримати його зараз?\n\nНатисніть \"Отримати нагороду\", щоб забрати бонус, або \"Скасувати\", якщо хочете зробити це пізніше.",
        "notify_wallet_connect": "Щоб отримати спеціальний бонус, підключіть свій гаманець до нашого додатку. Це швидко і просто!\n\nНатисніть \"Підключити\", щоб розпочати, або \"Скасувати\", якщо хочете зробити це пізніше.",
        "notify_new_level": "Ви успішно досягли нового рівня в нашому додатку! Дякуємо за вашу активність і захопленість.\n\nПродовжуйте насолоджуватися додатком і досягати нових висот!",
        "notify_tg_channel_sub": "Підпишіться на наш канал у Telegram і будьте в курсі останніх новин. Беріть участь у різних активностях спільноти. Дізнавайтеся секрети та отримуйте нагороди."
      },
      "buttons": {
        "notify_3_hour": "Отримати нагороду",
        "notify_24_hour": "Отримати нагороду",
        "notify_wallet_connect": "Підключити",
        "notify_new_level": "Прийняти",
        "notify_tg_channel_sub": "Приєднатися"
      }
    }
  },
  "pages": {
    "store": {
      "card_char": {
        "tap_reward": "Винагорода за Тап",
        "energy" : "Енергія",
        "autofarm_duration" : "Тривалість автофарминга",
        "autofarm_reward" : "Винагорода за автофарминг",
        "game_daily_count" : "Ігор на день",
        "balance" : "Баланс",
        "game_duration" : "Тривалість игры",
        "game_reward_line" : "Винагорода за лінію",
        "game_reward_win" : "Винагорода за гру",
        "multitap" : "Мультітап",
        "autofarm_notify": "Повідомлення автофармінг"
      }
    },
    "invite_friends": {
      "title": "Запросити друзів",
      "desc": "Ви можете отримати 10% від суми, яку принесли ваші реферали",
      "invite_a_friend": "Запросити друга",
      "for_you_and_your_friends": "Для вас та вашого друга",
      "empty_list_friends": "Список рефералок порожній"
    },
    "task": {
      "title": "Заробляйте більше монет",
      "statuses": {
        "done": "готово",
        "start": "почати",
        "wait": "зачекайте",
        "unavailable": "недоступно"
      },
      "ad_error": "Сталася помилка, спробуйте пізніше"
    },
    "wallet": {
      "title": "Підключіть ваш гаманець",
      "title_filled": "Ваш гаманець {alias}"
    },
    "game": {
      "how_to_play": "Як грати?",
      "finish_the_game": "Завершити гру",
      "next_blocks": "Наступні фігури:",
      "no_attempts": "Немає спроб. Спробуйте пізніше",
      "count_attempts": "ГРАТИ ({try_left}/{try_counts})",
      "instruction_for_game": {
        "game_instruction": "Інструкція до гри",
        "control": "Керування:",
        "game_end": "Кінець гри:",
        "end_game_condition": "Гра закінчується, коли нові фігури більше не можуть поміститися на ігровому полі. Також гра автоматично закінчується через 60 секунд. Спробуйте набрати якомога більше очок за цей час!",
        "left_control": "Перемістити фігуру вліво: Використовуйте стрілку вліво на клавіатурі або проведіть пальцем вліво на екрані, щоб перемістити фігуру вліво.",
        "right_control": "Перемістити фігуру вправо: Використовуйте стрілку вправо на клавіатурі або проведіть пальцем вправо на екрані, щоб перемістити фігуру вправо.",
        "down_control": "Прискорити падіння: Натисніть стрілку вниз на клавіатурі або утримуйте палець на екрані, щоб прискорити падіння фігури.",
        "rotate_control": "Повернути фігуру: Натисніть стрілку вгору на клавіатурі або зробіть короткий тап на екрані, щоб повернути фігуру за годинниковою стрілкою."
      }
    }
  },
  "component": {
    "store_card": {
      "limit_is_used": "Ліміт вичерпано",
      "buy_for": "Купити за"
    }
  }
}
