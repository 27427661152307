import { createI18n } from 'vue-i18n'

const fileNameToLocaleModuleDict = import.meta.glob<{ default: Record<string, string> }>('./locales/*.json', {
  eager: true,
})

const messages: { [P: string]: Record<string, string> } = {}
Object.entries(fileNameToLocaleModuleDict)
  .map(([fileName, localeModule]) => {
    const fileNameParts = fileName.split('/')
    const fileNameWithoutPath = fileNameParts[fileNameParts.length - 1]
    const localeName = fileNameWithoutPath.split('.json')[0]

    return [localeName, localeModule.default] as const
  })
  .forEach((localeNameLocaleMessagesTuple) => {
    messages[localeNameLocaleMessagesTuple[0]] = localeNameLocaleMessagesTuple[1]
  })

const i18n = createI18n({
  legacy: false,
  locale: 'en',
  fallbackLocale: 'en',
  globalInjection: true,
  messages,
  allowComposition: true,
  missingWarn: false,
  fallbackWarn: false,
  silentFallbackWarn: true,
  silentTranslationWarn: true,
  warnHtmlMessage: false,
  escapeParameter: true
})

// Безопасная установка локали
export const setI18nLanguage = (locale: string) => {
  try {
    if (i18n.global.locale && messages[locale]) {
      i18n.global.locale.value = locale
      document.querySelector('html')?.setAttribute('lang', locale)
    }
  } catch (error) {
    console.warn('Failed to set i18n language:', error)
    i18n.global.locale.value = 'en'
  }
}

export default i18n
