<script lang="ts">
import { defineComponent } from 'vue'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import PageTransition from '../components/base/PageTransition.vue'

export default defineComponent({
  name: 'TechnicalWork',
  components: {
    PageTransition
  },
})
</script>
<template>
  <PageTransition>
    <div class="min-h-screen h-full bg-black text-white flex flex-col items-center justify-center relative">
      <img src="/image/bg-main.png" class="absolute z-10 h-100 w-100 main-bg" />
      <img src="/image/tech_work.webp" class="absolute z-20 h-100 w-100 lines !opacity-100" />
      <div class="backgroun-gradient"></div>
      <div class="h-full p-4 flex flex-col flex-1 items-center justify-center z-30">
        <div class="flex flex-col items-center font-pixelify text-7xl text-center whitespace-break-spaces">
          {{ $t('basic.not_found') }}
        </div>
      </div>
    </div>
  </PageTransition>
</template>
<style scoped>
.lines {
  display: block;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.2;
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;
  object-fit: cover;
}
.main-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.1;
}
.backgroun-gradient {
  content: ' ';
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.8;
  z-index: 15;
  background: radial-gradient(50% 50% at 50% 50%, #1b1c1b 0%, #0b0c0a 100%);
}
</style>
