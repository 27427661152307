<script setup lang="ts">
import { onMounted, ref, watch, defineProps } from 'vue'

const props = defineProps<{
  ready?: boolean
}>()

const isVisible = ref(false)

watch(
  () => props.ready,
  (newValue) => {
    if (newValue) {
      requestAnimationFrame(() => {
        isVisible.value = true
      })
    }
  },
  { immediate: true }
)

onMounted(() => {
  if (!props.ready) {
    requestAnimationFrame(() => {
      isVisible.value = true
    })
  }
})
</script>

<template>
  <div class="page-transition" :class="{ 'page-transition--visible': isVisible }">
    <slot></slot>
  </div>
</template>

<style scoped>
.page-transition {
  opacity: 0;
  transform: translateY(20px);
  transition:
    opacity 0.7s ease-out,
    transform 0.7s ease-out;
}

.page-transition--visible {
  opacity: 1;
  transform: translateY(0);
}
</style>
