<script lang="ts">
import { defineComponent } from 'vue'
import VButton from '../../components/base/VButton.vue'
import Loader from '../../components/Loader.vue'
import { ORANGE_THEME } from '../../consts/buttonThemes'
import ApiService from '../../api/ApiService'
import { useToast } from 'vue-toastification'

export default defineComponent({
  name: 'TechnicalWork',
  components: { Loader, VButton },
  setup() {
    const toast = useToast()
    return {
      toast,
    }
  },
  data() {
    return {
      isLoading: false,
    }
  },
  methods: {
    ORANGE_THEME() {
      return ORANGE_THEME
    },
    async ping() {
      this.isLoading = true
      try {
        const response = await ApiService.getInstance().ping()
        if (response.status === 'success' && response.result === 'working') {
          this.toast.success(this.$t('basic.app_is_working'))
          this.$router.push({ name: 'welcome' })
          return
        }
        this.toast.error(this.$t('basic.server_unavailable'))
      } catch (err) {
        this.toast.error(this.$t('modals.server_unavailable'))
      } finally {
        this.isLoading = false
      }
    },
  },
})
</script>
<template>
  <div class="min-h-screen h-full bg-black text-white flex flex-col items-center justify-center relative">
    <img src="/image/bg-main.png" class="absolute z-10 h-100 w-100 main-bg" />
    <img src="/image/tech_work.webp" class="absolute z-20 h-100 w-100 lines !opacity-100" />
    <div class="backgroun-gradient"></div>
    <div class="h-full p-4 flex flex-col flex-1 items-center justify-center z-30">
      <div class="flex flex-col items-center font-pixelify text-7xl text-center">{{ $t('basic.teh_work') }}</div>
      <VButton
        v-if="!isLoading"
        class="z-20 mt-16"
        :text="$t('basic.retry')"
        :custom-text-classes="['font-pixelify', 'text-2xl', ORANGE_THEME()]"
        :theme="ORANGE_THEME()"
        @click="ping"
      />
      <Loader v-else class="mt-16" :custom-class="['!h-10', '!w-10']" />
    </div>
  </div>
</template>
<style scoped>
.lines {
  display: block;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.2;
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;
  object-fit: cover;
}
.main-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.1;
}
.backgroun-gradient {
  content: ' ';
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.8;
  z-index: 15;
  background: radial-gradient(50% 50% at 50% 50%, #1b1c1b 0%, #0b0c0a 100%);
}
</style>
