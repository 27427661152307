<script>
import VLink from './VLink.vue'
import MiningIcon from '../icons/MiningIcon.vue'
import FriendsIcon from '../icons/FriendsIcon.vue'
import TasksIcon from '../icons/TasksIcon.vue'
import WalletIcon from '../icons/WalletIcon.vue'
import FarmingIcon from '../icons/FarmingIcon.vue'
import NFTIcon from '../icons/NFTIcon.vue'
const navList = [
  {
    name: 'main',
    text: 'Farming',
    icon: FarmingIcon,
  },
  {
    name: 'store',
    text: 'Mining',
    icon: MiningIcon,
  },
  {
    name: 'invite_friends',
    text: 'Friends',
    icon: FriendsIcon,
  },
  {
    name: 'task',
    text: 'Tasks',
    icon: TasksIcon,
  },
  {
    name: 'nft',
    text: 'NFT',
    icon: NFTIcon,
  },
  {
    name: 'wallet',
    text: 'Wallet',
    icon: WalletIcon,
  },
]
export default {
  name: 'VFooter',
  components: {
    VLink,
  },
  computed: {
    navList() {
      return navList
    },
  },
}
</script>

<template>
  <div class="footer pixel flex justify-around items-center text-xs">
    <VLink v-for="{ name, text, icon } in navList" :key="text" class="p-1.5" :name="name" :text="text">
      <component :is="icon"></component>
    </VLink>
  </div>
</template>
