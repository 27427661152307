import { App } from 'vue'
import { I18n } from 'vue-i18n'

export default {
  install: (app: App, i18n: I18n) => {
    // Перехватываем ошибки локализации
    i18n.global.fallbackFormat = (message, context) => {
      console.warn('i18n fallback format:', { message, context })
      return message
    }

    // Обработчик ошибок для компиляции сообщений
    i18n.global.messageCompiler = (message) => {
      try {
        return typeof message === 'function' ? message : () => message
      } catch (error) {
        console.warn('i18n message compilation error:', error)
        return () => message
      }
    }

    // Глобальный обработчик ошибок Vue
    app.config.errorHandler = (err, instance, info) => {
      // Проверяем, является ли ошибка связанной с i18n
      if (err?.message?.includes('i18n') || err?.message?.includes('locale')) {
        console.warn('Vue I18n Error:', err)
      }

      // Для остальных ошибок используем стандартное поведение
      console.error('Global error:', err)
      console.error('Component:', instance)
      console.error('Info:', info)
    }
  },
}
