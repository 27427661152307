<template>
  <div class="button">
    <div class="left">
      <div class="vline1">
        <div class="q1" :class="theme"></div>
      </div>
      <div class="vline2">
        <div class="w1" :class="theme"></div>
        <div class="w2" :class="theme"></div>
      </div>
      <div class="vline3">
        <div class="e1" :class="theme"></div>
        <div class="e2" :class="theme"></div>
        <div class="e3" :class="theme"></div>
        <div class="e4" :class="theme"></div>
      </div>
      <div class="vline4">
        <div class="r1" :class="theme"></div>
        <div class="r2" :class="theme"></div>
        <div class="r3" :class="theme"></div>
      </div>
      <div class="vline5">
        <div class="l1" :class="theme"></div>
        <div class="l2" :class="theme"></div>
        <div class="l3" :class="theme"></div>
        <div class="l4" :class="theme"></div>
        <div class="l5" :class="theme"></div>
      </div>
    </div>

    <div class="middle" >
      <div class="Line-1" :class="theme"></div>
      <div class="Line-2" :class="theme"></div>
      <div class="Line-3 flex items-center justify-center" :class="customTextClasses">{{ text }}</div>
      <div class="Line-4" :class="theme"></div>
      <div class="Line-5" :class="theme"></div>
    </div>

    <div class="right">
      <div class="vline1">
        <div class="a1" :class="theme"></div>
        <div class="a2" :class="theme"></div>
        <div class="a3" :class="theme"></div>
        <div class="a4" :class="theme"></div>
        <div class="a5" :class="theme"></div>
      </div>
      <div class="vline2">
        <div class="x1" :class="theme"></div>
        <div class="x2" :class="theme"></div>
        <div class="x3" :class="theme"></div>
      </div>
      <div class="vline3">
        <div class="t1" :class="theme"></div>
        <div class="t2" :class="theme"></div>
        <div class="t3" :class="theme"></div>
        <div class="t4" :class="theme"></div>
      </div>
      <div class="vline4">
        <div class="v1" :class="theme"></div>
        <div class="v2" :class="theme"></div>
      </div>
      <div class="vline5">
        <div class="d1" :class="theme"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VButton',
  props: {
    text: {
      type: String,
      required: true,
    },
    customTextClasses: {
      type: Array,
      required: false,
      default: () => [],
    },
    theme: {
      type: String,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../scss/basic_button';

.button {
  position: relative;
  transition: transform 0.1s ease-out;
  transform: translateY(0);
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

.button:active {
  transform: translateY(4px);
}
</style>
