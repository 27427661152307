import { createApp } from 'vue'
import i18n from './i18n'
import { createGtm } from '@gtm-support/vue-gtm'
import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'
import stores from './stores'
import router from './router'
import App from './App.vue'
import VueToastificationConfig from './configs/vue-toastification-config'
import VueQueryPlugin, { queryClient } from './plugins/vueQuery'
import i18nErrorHandler from './plugins/i18nErrorHandler'

const app = createApp(App)

// Устанавливаем обработчик ошибок i18n до других плагинов
app.use(i18nErrorHandler, i18n)

// Добавляем window.onerror для отлова глобальных ошибок JavaScript
window.onerror = function (message, source, lineno, colno, error) {
  console.error('Global Error:', {
    message,
    source,
    lineno,
    colno,
    error,
  })
  return false
}

// Добавляем обработчик непойманных промисов
window.addEventListener('unhandledrejection', function (event) {
  console.error('Unhandled Promise Rejection:', {
    reason: event.reason,
    promise: event.promise,
  })
})

app.use(i18n)
app.use(stores)
app.use(router)
app.use(Toast, VueToastificationConfig)
app.use(VueQueryPlugin, { queryClient })

if (import.meta.env.VITE_APP_GTM_ENABLED) {
  app.use(
    createGtm({
      id: import.meta.env.VITE_APP_GTM_KEY,
      debug: false,
      vueRouter: router,
    }),
  )
}

app.mount('#app')
