{
  "basic": {
    "hello": "Привет",
    "retry": "Проверить подключение",
    "server_unavailable": "Сервер все еще не доступен попробуйте позже",
    "app_is_working": "Приложение снова работает!",
    "start": "Начать",
    "claim": "Получить",
    "loading": "Пожалуйста, подождите, идет загрузка...",
    "edit": "Редактировать",
    "done": "Готово!",
    "deposit": "Депозит",
    "custom_wallet": "пользовательский кошелек",
    "friends": "Друзья",
    "world": "Мир",
    "week": "Неделя",
    "top_5_by_ref": "ТОП 5 \nлидеры по рефералкам",
    "top_5_by_balance": "ТОП 5 \nлидеры по коинам",
    "text_copy": "Ссылка скопирована",
    "teh_work": "ТЕХНИЧЕСКИЕ РАБОТЫ",
    "not_enough_money": "Не достаточно денег",
    "success": "Успешно",
    "start_farming": "Запустить фарминг",
    "farming_button": "Фармится",
    "claim_coins": "Собрать {amount}",
    "bonus": "Бонус:",
    "summary": "Итого:",
    "score": "Набранно очков:",
    "back_to_home": "Вернутся на главную",
    "game_over": "Игра завершена",
    "win_game": "Поздравляем вы выиграли!",
    "loose_game": "Вы проиграли, попробуйте еще",
    "time": "Время",
    "score_user": "Счет",
    "change_lang": "Сменить язык на {lang}"
  },
  "header": {
    "close": "Закрыть",
    "profit_for_one_tap": "Прибыль за 1 тап",
    "level": "уровень",
    "profit_per_hour": "Прибыль в час",
    "level_number": "Ур.{level}"
  },
  "footer": {
    "main": "Главная",
    "store": "Магазин",
    "task": "Задачи",
    "wallet": "Кошелек",
    "invite_friends": "Друзья",
    "nft": "NFT"
  },
  "modals": {
    "purchased_item": {
      "title": "Поздравляем с покупкой",
      "button": "Готово!",
      "description": "Вы купили {name} = {amount}"
    },
    "crypto_modal": {
      "wallet_saved": "Кошелек сохранен",
      "wallet_save_error": "Ошибка! Пожалуйста, проверьте введенный вами кошелек.",
      "address_text": "Введите адрес вашего кошелька USDT ERC-20 для получения токенов",
      "have_account": "У вас есть аккаунт {alias} ?",
      "receive_rewards": "Чтобы получить награды, вам нужно добавить адрес вашего кошелька",
      "wallet_filled_title": "Кошелек уже заполнен",
      "wallet_filled_desc": "Кошелек уже заполнен, хотите его изменить?",
      "inputs": {
        "postal_address": {
          "placeholder": "кошелек"
        }
      },
      "buttons": {
        "create_account": "Создать аккаунт",
        "there_are": "Да, есть"
      }
    },
    "info_modal": {
      "titles": {
        "notify_3_hour": "Поздравляем",
        "notify_24_hour": "Поздравляем",
        "notify_wallet_connect": "Подключите свой кошелёк",
        "notify_new_level": "Уровень повышен",
        "notify_tg_channel_sub": "Для продолжения Вам необходимо подписаться на наш Telegram канал"
      },
      "descriptions": {
        "notify_3_hour": "Вы провели 3 часа в нашем приложении! Спасибо за вашу активность.\n\nВ качестве награды мы предлагаем вам специальный бонус. Хотите получить его сейчас?\n\nНажмите \"Перейти\", чтобы забрать награду, или х, если хотите сделать это позже.",
        "notify_24_hour": "Вы провели 24 часа в нашем приложении! Спасибо за вашу активность.\n\nВ качестве награды мы предлагаем вам специальный бонус. Хотите получить его сейчас?\n\nНажмите \"Перейти\", чтобы забрать награду, или х, если хотите сделать это позже.",
        "notify_wallet_connect": "Чтобы получить специальный бонус, подключите свой кошелёк к нашему приложению. Это быстро и просто!\n\nНажмите \"Подключить\", чтобы начать, или x, если хотите сделать это позже.\n",
        "notify_new_level": "Вы успешно достигли нового уровня в нашем приложении! Спасибо за вашу активность и увлечённость.\nПродолжайте наслаждаться приложением и достигайте новых высот!",
        "notify_tg_channel_sub": "Подпишитесь на наш канал в Telegram и будьте в курсе последних новостей. Участвуйте в различных активностях сообщества. Узнавайте секреты и получайте награды.\n"
      },
      "buttons": {
        "notify_3_hour": "Перейти к награде",
        "notify_24_hour": "Перейти к награде",
        "notify_wallet_connect": "Подключить",
        "notify_new_level": "Принять",
        "notify_tg_channel_sub": "Присоединиться"
      }
    },
    "secret": {
      "title": "Промокод",
      "enter_promocode": "Введите промокод",
      "promocode_applied": "Промокод применен",
      "you_got": "Вам выпало",
      "submit": "Отправить",
      "try_again": "Попробовать еще раз",
      "get_reward": "Получить награду",
      "close": "Закрыть"
    },
    "no_attempts": {
      "title": "Попытки закончились",
      "description": "Вы использовали все ежедневные попытки. Посмотрите рекламу, чтобы получить дополнительную попытку!",
      "watch_ad": "Смотреть рекламу"
    },
    "mint_nft": {
      "title": "Купить NFT",
      "price": "Цена",
      "connect_wallet": "Подключить кошелек",
      "mint_button": "Купить",
      "connected_wallet": "Подключенный кошелек",
      "success": "NFT успешно куплен",
      "error": "Ошибка при покупке NFT",
      "connect_error": "Не удалось подключить кошелек. Пожалуйста, попробуйте снова.",
      "mint_error": "Не удалось купить NFT. Пожалуйста, проверьте баланс и попробуйте снова."
    }
  },
  "pages": {
    "store": {
      "card_char": {
        "tap_reward": "Вознаграждение за Тап",
        "energy" : "Энергия",
        "autofarm_duration" : "Длительность автофарминга",
        "autofarm_reward" : "Вознаграждение за автофарминг",
        "game_daily_count" : "Игр в день",
        "balance" : "Баланс",
        "game_duration" : "Длительность игры",
        "game_reward_line" : "Вознаграждение за линию",
        "game_reward_win" : "Вознаграждение за игру",
        "multitap" : "Мультитап",
        "autofarm_notify": "Уведомления автофарминг"
      }
    },
    "invite_friends": {
      "title": "Пригласить друзей",
      "desc": "Вы можете получить 10% от суммы, которую принесли ваши рефералы",
      "invite_a_friend": "Пригласить друга",
      "for_you_and_your_friends": "Для вас и вашего друга",
      "empty_list_friends": "Список рефералок пуст"
    },
    "task": {
      "title": "Зарабатывайте больше монет",
      "statuses": {
        "done": "готово",
        "start": "начать",
        "wait": "подождите",
        "unavailable": "недоступно"
      },
      "ad_error": "Произошла ошибка, попробуйте позже"
    },
    "wallet": {
      "title": "Подключите ваш кошелек",
      "title_filled": "Ваш кошелек {alias}",
      "header_empty_wallet" : "Получайте настоящие криптовалюты. Зарабатывайте и покупайте любые токены.",
      "wallet_is_already_linked" : "Ваш кошелек уже подключен",
      "change_wallet" : "Изменить кошелек"
    },
    "game": {
      "how_to_play": "Как играть?",
      "finish_the_game": "Завершить игру",
      "next_blocks": "Следующие фигуры:",
      "no_attempts": "Попыток нет. Попробуйте позже",
      "count_attempts": "ИГРАТЬ ({try_left}/{try_counts})",
      "instruction_for_game": {
        "game_instruction": "Инструкция к игре",
        "control": "Управление:",
        "game_end": "Конец игры:",
        "end_game_condition": "Игра заканчивается, когда новые фигуры больше не могут поместиться на игровом поле. Также игра автоматически заканчивается через 60 секунд. Попробуйте набрать как можно больше очков за это время!",
        "left_control": "Переместить фигуру влево: Используйте стрелку влево на клавиатуре или проведите пальцем влево по экрану, чтобы переместить фигуру влево.",
        "right_control": "Переместить фигуру вправо: Используйте стрелку вправо на клавиатуре или проведите пальцем вправо по экрану, чтобы переместить фигуру вправо.",
        "down_control": "Ускорить падение: Нажмите стрелку вниз на клавиатуре или удерживайте палец на экране, чтобы ускорить падение фигуры.",
        "rotate_control": "Повернуть фигуру: Нажмите стрелку вверх на клавиатуре или сделайте короткое нажатие на экране, чтобы повернуть фигуру по часовой стрелке."
      }
    },
    "nft": {
      "loading": "Загрузка NFT...",
      "mint_button": "СОЗДАТЬ",
      "my_nfts_button": "МОИ NFT",
      "mint_success": "NFT успешно создан!",
      "mint_error": "Не удалось создать NFT. Попробуйте еще раз.",
      "holding_description": "Держатели NFT получают токены. Скоро доступно на маркетплейсах"
    }
  },
  "component": {
    "store_card": {
      "limit_is_used": "Лимит исчерпан",
      "buy_for": "Купить за"
    }
  }
}
