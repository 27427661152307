<script setup>
import { useI18n } from 'vue-i18n'
import { RouterLink } from 'vue-router'
const { t } = useI18n()
const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  text: {
    type: String,
    required: true,
  },
})
</script>

<template>
  <RouterLink exact-active-class="active-class" class="flex flex-col items-center" :to="{ name: props.name }">
    <slot></slot> {{ t(`footer.${props.name}`) }}
  </RouterLink>
</template>

<style scoped>
.active-class {
  background-color: #0b0c0a0d;
}
</style>
