import { VueQueryPlugin, QueryClient } from '@tanstack/vue-query'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 4, // данные считаются свежими в течение 2 минут
      cacheTime: 1000 * 60 * 4, // кэш хранится 2 минуты
      refetchOnWindowFocus: true,
      refetchOnMount: false,
      refetchOnReconnect: true,
      // refetchInterval: 1000 * 60, // глобальный интервал рефетча - 1 минута
      refetchIntervalInBackground: true, // по умолчанию не обновляем в фоне
      notifyOnChangeProps: ['data'], // реагируем только на изменение data
      keepPreviousData: true, // сохраняем предыдущие данные при обновлении
      refetchOnBackground: true, // разрешаем фоновое обновление
      onError: (error) => {
        console.error('Query Error:', {
          message: error.message,
          stack: error.stack,
          cause: error.cause,
        })
      },
    },
    mutations: {
      onError: (error) => {
        console.error('Mutation Error:', {
          message: error.message,
          stack: error.stack,
          cause: error.cause,
        })
      },
    },
  },
})

export { queryClient }
export default VueQueryPlugin
